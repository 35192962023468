import React, { useState } from "react";
import PropTypes from "prop-types";
import Div from "app/components/Div";
import Lottie from "react-lottie-light";
import ClassRow from "app/components/ClassRow";
import singleCardAnimationData from "./lottie-single-card.json";

const ClassRowWithAnimation = ({ classData }) => {
  const [showClassRow, toggleClassRow] = useState(false);
  return (
    <>
      {!showClassRow && (
        <Lottie
          options={{
            autoplay: true,
            loop: false,
            // container: document.querySelector("#class-row"),
            animationData: singleCardAnimationData,
          }}
          eventListeners={[
            {
              eventName: "complete",
              callback: () => toggleClassRow(true),
            },
          ]}
        />
      )}
      <Div
        borderWidth="3px"
        borderStyle="solid"
        borderRadius="2px"
        borderColor="bulbaGreen"
        mb="10px"
        width="100%"
        opacity={showClassRow ? 1 : 0}
        transition="opacity 150ms ease-in"
        position={showClassRow ? "relative" : "absolute"}
      >
        <ClassRow classData={classData} borderColor="bulbaGreen" />
      </Div>
    </>
  );
};

ClassRowWithAnimation.propTypes = {
  classData: PropTypes.shape({}).isRequired,
};

export default ClassRowWithAnimation;
